<template>
  <div>
    <v-progress-circular v-if="!goal.pivot.completed_at"
                         :color="getColor(goal.pivot.complete_value*100)"
                         style="font-size: 12px"
                         :size="40"
                         :value="goal.pivot.complete_value*100"
                         striped
    >
      {{ goal.pivot.complete_value * 100 }}
    </v-progress-circular>
    <v-icon color="green" size="40" v-else>
      mdi-check-circle
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "GoalProgressBar",
  props: ['goal'],
  methods: {
    getColor(percent) {
      if (percent < 50) return 'red';
      if (percent < 70) return 'blue';
      if (percent < 80) return 'dark-blue';
      else return 'green';
    }
  }
}
</script>

<style scoped>

</style>
