<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs" class="d-flex align-center">
          <v-badge
              :value="user.online"
              bordered
              bottom
              color="green accent-4"
              dot
              offset-x="10"
              offset-y="10"
          >
          <v-avatar size="30">
            <v-img :lazy-src="user.avatar_16" :src="user.avatar"></v-img>
          </v-avatar>
          </v-badge>
          <div class="ml-2">
            <div>
            </div>
            <div>
              <div class="d-flex">
                <div class="name max-1-lines">
                  {{ user.name }}
                </div>
              </div>


            </div>
            <div class="date">
              {{ text }}
            </div>
          </div>

        </div>
      </template>
      <v-list dense>
        <v-list-item :to="{name: 'profile', params: {id: user.id}}">
          <v-list-item-content>
            Перейти в профиль
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{path: $vuetify.breakpoint.smAndDown ? '/messages' : '#', query: {chat_user_id: user.id}}">
          Написать сообщение
        </v-list-item>
      </v-list>
    </v-menu>
  </div>

</template>

<script>
export default {
  name: "UserLinkComponent",
  props: ['user', 'text', 'from', 'fromLink']
}
</script>

<style scoped>

.max-1-lines{
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date {
  font-size: 12px;
  font-weight: 300;
}

.from {
  font-size: 12px;
  font-weight: normal;
  color: #767d83;
}

.name {
  font-weight: 400;
  font-size: 16px;
  line-height: 1em;
}

</style>
