<template>
  <CardUI class="slot" v-if="goals.length > 0">
    <template v-slot:header>Контрольные точки проекта</template>
    <template v-slot:body>
      <div>

      </div>

          <div v-for="goal in goals" :key="goal.id" class="goal mt-2">
            <div class="d-flex align-center">
              <div class="pr-4">
                <GoalProgressBar :goal="goal"/>
              </div>
              <div style="font-size: 14px">
                <b>
                  {{ goal.name }}
                </b>
                <div>
                  <div style="color: #1f1f1f">
                    {{ goal.description.toLowerCase() }}
                  </div>
                  <div v-if="goal.pivot.deadline && !goal.pivot.completed_at"
                       :class="{'color-red':  $moment.utc(goal.pivot.deadline).isBefore($moment.utc())}" class="mt-1"
                       style="font-size: 10px">
                    <v-icon color="red" v-if="$moment.utc(goal.pivot.deadline).isBefore($moment.utc())" title="Дедлайн"
                            size="12">
                      mdi-alert
                    </v-icon>
                    <v-icon v-else title="Дедлайн" size="12">mdi-clock</v-icon>
                    Срок исполнения: {{ $moment.utc(goal.pivot.deadline).fromNow() }}
                  </div>
                </div>
              </div>
            </div>
          </div>
    </template>

  </CardUI>
</template>

<script>
import projects from "@/api/projects.js";
import CardUI from "@/components/UI/CardUI.vue";
import GoalProgressBar from "@/components/Opd/GoalProgressBar.vue";

export default {
  name: "ProjectsItemGoalsComponent",
  components: {CardUI, GoalProgressBar},
  data() {
    return {
      goals: []
    }
  },
  computed: {
    project() {
      return this.$store.state.projects.currentProject;
    },
  },
  methods: {
    getColor(percent) {
      if (percent < 50) return 'red';
      if (percent < 70) return 'blue';
      if (percent < 80) return 'dark-blue';
      else return 'green';
    }
  },
  watch: {
    project() {
      projects.goals({id: this.$store.state.projects.currentProject.id}).then((r) => {
        this.goals = r.data.sort((a, b) => a.pivot.complete_value - b.pivot.complete_value);
      });
    }
  },

  mounted() {
    projects.goals({id: this.$store.state.projects.currentProject.id}).then((r) => {
      this.goals = r.data.sort((a, b) => a.pivot.complete_value - b.pivot.complete_value);
    });
  }
}
</script>

<style scoped>
.goal {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eaeaea;
  min-width: 250px;
  height: 90px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}


</style>
