<template>
  <div>
    <v-sheet style="border-radius: 20px;" class="pa-4">
      <h4 class="title">Информация об обучении</h4>
      <span class="mt-4 d-block">
        {{ project.pool.name }}
        <br/>
        с
        {{$moment(project.pool.start_at).format('DD MMMM')}} по
        {{$moment(project.pool.end_at).format('DD MMMM YYYY')}} г.
        <br/>
        <br/>
        <b class="mb-4 d-block">Наставник</b>
        <UserLinkComponent v-if="project.tutor" :user="project.tutor"/>
        <div v-else>Не выбран</div>
      </span>
    </v-sheet>
    <ProjectsItemGoalsComponent class="my-5"/>
    <h4 v-if="exams.length" class="my-4">Аттестация</h4>
    <Exam class="mb-2" :exam="exam" v-for="exam in exams" :key="exam.id"/>
  </div>
</template>

<script>
import Exam from "@/components/Exams/Exam";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";
import ProjectsItemGoalsComponent from "@/components/Projects/ProjectsItem/ProjectsItemGoalsComponent";

export default {
  name: "ProjectsItemExamsComponent",
  components: {ProjectsItemGoalsComponent, UserLinkComponent, Exam},
  computed: {
    exams() {
      return this.$store.state.projects.currentProjectExams
    },
    project() {
      return this.$store.state.projects.currentProject
    }
  },
  mounted() {
    this.$store.dispatch('getProjectExams')
  }
}
</script>

<style scoped>

</style>
