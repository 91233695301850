<template>
  <div class="exam">
    <div class="exam__header flex-md-row flex-column">
      <h4 class="flex-shrink-0 mr-4">{{ exam.name }}</h4>
      <v-spacer/>
      {{ $moment.utc(exam.start_at).local().format('DD.MM.YYYY') }} -
      {{ $moment.utc(exam.end_at).local().format('DD.MM.YYYY') }}
    </div>
    <div v-if="exam.is_student">
      Ваша оценка: {{exam.score}} {{$plural(exam.score, 'балл', 'балла', 'баллов')}}
    </div>
    <v-btn @click="isSetScoreDialogShow = true" v-if="exam.can_set_points" class="mt-2" outlined small rounded
           color="primary">Выставить баллы
    </v-btn>
    <v-dialog max-width="800" v-model="isSetScoreDialogShow">
      <CardUI>
        <template v-slot:header>
          <h4>
            Оценочный лист {{ exam.name }}
          </h4>
        </template>
        <template v-slot:body>
          <div class="score">
            <v-alert
                      type="info">
              Выставьте баллы каждому участнику. Внизу (под всеми участниками) нажмите кнопку сохранить.
              Помните, что оценку в 0 баллов необходимо проставить.
            </v-alert>
            <div class="student" :key="student.id" v-for="student in exam.students">
              <UserLinkComponent class="mb-2" :user="student"/>
              <div class="test" :key="test.id" v-for="test in exam.tests">
                <div>
                  <div class="test__name">
                    {{ test.name }}
                  </div>
                  <div class="mr-2" style="text-align: left; font-size: 12px; font-weight: 200">
                    Оценка
                    от
                    {{test.min_score}}
                    до
                    {{test.max_score}}
                  </div>
                </div>
                <div>
                  <input pattern="[0-9]*" class="score_input" @input="(e) => onChangeScore(test.id, student.id,  e.target.value)"  type="number"
                                :min="test.min_score" placeholder="-" :max="test.max_score"
                                :value="scores[test.id][student.id]"/>
                </div>

              </div>
            </div>
          </div>

          <v-btn @click="save" :loading="isSavingChanges" class="mt-2" color="primary" rounded outlined>Сохранить</v-btn>
        </template>
      </CardUI>
    </v-dialog>
  </div>
</template>

<script>
import CardUI from "@/components/UI/CardUI";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";
import projects from "@/api/projects";

export default {
  name: "Exam",
  components: {UserLinkComponent, CardUI},
  props: ['exam'],
  data() {
    return {
      isSetScoreDialogShow: false,
      changes: {},
      scores: [],
      isSavingChanges: false
    }
  },
  mounted() {
    this.scores = this.exam.scores;
  },
  methods: {
    onChangeScore(test_id, student_id, score) {
      let localChanges = {...this.changes};
      if(!localChanges[test_id]){
        localChanges[test_id] = {};
      }
      localChanges[test_id][student_id] = parseInt(score);
      this.changes = localChanges;
    },

    save(){
      this.isSavingChanges = true;
      projects.saveExams({id: this.$store.state.projects.currentProject.id, exam_id: this.exam.id, changes: this.changes}).then((r) => {
        this.scores = r.data;
        this.changes = {};
        this.$store.dispatch('getProjectExams')
      }).finally(() => {
        this.isSavingChanges = false;
      })

    }
  }
}
</script>

<style scoped>
.exam {
  width: 100%;
  padding: 20px;
  background: #efefef;
  border-radius: 10px;
}

.exam__header {
  display: flex;
}

.score_list {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  padding: 10px;
}

.score_list td, .score_list th {
  padding: 10px;
  text-align: left;
}

.test:nth-child(even) {
  background: #edf0fc;
}

.student {
  border: 1px solid #eaeaea;
  padding: 10px;
  border-radius: 10px;
}

.test {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-radius: 10px;
  align-items: center;

}

.test__name {
  font-size: 14px;
  font-weight: 400;
}

.score_input{
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;

}
.score_input:focus{
  outline: none;
}
</style>
